import { createSlice } from '@reduxjs/toolkit';

import { FILE_TYPES, PLAN_SCREEN_SECTION, PLAN_STATUS, PRODUCER_SETUPS } from 'appConstants';
import type {
  Report,
  IResponse,
  ThunkArgs,
  Category,
  CategoryData,
  Organisation,
  Beneficiary,
  Results,
  Share,
  Audits,
  DocumentsList,
  FetchReviews,
  Undelete,
  PlanWrapper,
  ChildOrganisation,
  IMessageProperties,
  IMessageItemSummary
} from 'types';
import { AppDispatch, RootState } from 'app/store';
import { selectCredentials } from 'features/login/redux';
import { IGroupedComments } from './CommentsContext';
import { CurrencyTypes } from 'components/CurrencyDropdown/CurrencyDropdown';
import type {
  ApiAssetType,
  ApiDeletedInvestment,
  ApiDocument,
  ApiDocumentPreview,
  ApiFTPlanSearchText,
  ApiFullPlan,
  ApiFullPlanResult,
  ApiItemData,
  ApiMessageItem,
  ApiMWCurrencyData,
  ApiOrganization,
  ApiOrganizationSimpleOut,
  ApiPastPlanQuery,
  ApiPlan,
  ApiPlanAudit,
  ApiPlanResult,
  ApiPlanStatus,
  ApiPlanWrapper,
  GenericHttpResult
} from '@agunity/api-v4';
import { apiService } from 'api/apiService';
import { FairTradeRequestParams } from 'api/axiosHttpClient';
import { ableToSubmit } from './validation';

export const hot = '../features/SPOreport/redux.ts';

export interface LoginState {
  searched: Report[];
  searchOptions: any;
  reports: Report[];
  reportsx: Report[];
  planWrapper: PlanWrapper[];
  report: Report | null;
  categories: CategoryData[];
  category: Category | null;
  organisation: Organisation;
  beneficiaries: Beneficiary[];
  beneficiariesID: Beneficiary[];
  results: Results | null;
  share: Share | null;
  audits: Audits[];
  documentsList: DocumentsList[];
  reviewsList: FetchReviews[];
  downloadDocument: string;
  undelete: Undelete;
  childOrganisation: ChildOrganisation[];
  fetchStatus: 'idle' | 'fetching';
  assetTypes: any[];
  isJustCommented: boolean;
  allComments: IGroupedComments | undefined;
  selectedCurrency: CurrencyTypes;
  currencyConversions: any[] | undefined;
  showFilterModal_DraftPlan: boolean;
  showFilterModal_ApprovedPlan: boolean;
  showFilterModal_PastPlan: boolean;
  pastPlanWrapper: PlanWrapper[];
  approvedPlanWrapper: PlanWrapper[];
  draftPlanWrapper: PlanWrapper[];
  planFilters: Record<PLAN_SCREEN_SECTION, Partial<ApiPastPlanQuery>>
};

const initialState: LoginState = {
  searched: [],
  searchOptions: {},
  reports: [],
  reportsx: [],
  planWrapper: [],
  report: null,
  categories: [],
  category: null,
  organisation: {} as Organisation,
  childOrganisation: [],
  beneficiaries: [],
  beneficiariesID: [],
  results: null,
  share: null,
  documentsList: [],
  downloadDocument: '',
  audits: [],
  reviewsList: [],
  undelete: {} as Undelete,
  fetchStatus: 'idle',
  assetTypes: [],
  isJustCommented: false,
  allComments: undefined,
  selectedCurrency: CurrencyTypes.Original,
  currencyConversions: undefined,
  showFilterModal_DraftPlan: true,
  showFilterModal_ApprovedPlan: true,
  showFilterModal_PastPlan: true,
  pastPlanWrapper: [],
  approvedPlanWrapper: [],
  draftPlanWrapper: [],
  planFilters: {
    [PLAN_SCREEN_SECTION.Draft]: {},
    [PLAN_SCREEN_SECTION.Approved]: {},
    [PLAN_SCREEN_SECTION.Past]: {}
  },
};

export const spoSlice = createSlice({
  name: 'spo',
  initialState,
  reducers: {
    resetSpoState: () => initialState,
    fetchStatus: (state, action) => {
      state.fetchStatus = action.payload;
    },
    organisation: (state, action) => {
      state.organisation = action.payload;
    },
    childOrganisation: (state, action) => {
      state.childOrganisation = action.payload;
    },
    searched: (state, action) => {
      state.searched = action.payload;
    },
    searchOptions: (state, action) => {
      state.searchOptions = { ...state.searchOptions, ...action.payload };
    },
    reports: (state, action) => {
      state.reports = action.payload;
    },
    reportsx: (state, action) => {
      state.reportsx = action.payload;
    },
    planWrapper: (state, action) => {
      state.planWrapper = action.payload;
    },
    report: (state, action) => {
      state.report = action.payload;
    },
    results: (state, action) => {
      state.results = action.payload;
    },
    share: (state, action) => {
      state.share = action.payload;
    },
    audits: (state, action) => {
      state.audits = action.payload;
    },
    undelete: (state, action) => {
      state.undelete = action.payload;
    },
    // goals: (state, action) => {
    //   if (action.payload.id < 0) return;
    //   const index = state.reports.findIndex(({ id }) => id === action.payload.id);
    //   if (index >= 0) state.organisation.goals[index] = action.payload;
    //   else if (action.payload.year) state.organisation.goals.push(action.payload);
    // },
    updateReport: (state, action) => {
      if (action.payload.id < 0) return;
      const index = state.reports.findIndex(
        ({ id }) => id === action.payload.id
      );
      if (index >= 0) state.reports[index] = action.payload as Report;
      else if (action.payload.name) state.reports.push(action.payload);
    },
    approval: (state, action) => {
      const index = state.reports.findIndex(
        ({ id }) => id === action.payload.id
      );
      if (index >= 0) state.reports[index].approved = action.payload.approval;
    },
    categories: (state, action) => {
      state.categories = action.payload;
    },
    beneficiaries: (state, action) => {
      state.beneficiaries = action.payload;
    },
    beneficiariesID: (state, action) => {
      state.beneficiariesID = action.payload;
    },
    documentsList: (state, action) => {
      state.documentsList = action.payload;
    },
    downloadDocument: (state, action) => {
      state.downloadDocument = action.payload;
    },
    reviewsList: (state, action) => {
      state.reviewsList = action.payload;
    },
    assetTypes: (state, action) => {
      state.assetTypes = action.payload;
    },
    isJustCommented: (state, action) => {
      state.isJustCommented = action.payload;
    },
    allComments: (state, action) => {
      state.allComments = action.payload;
    },
    selectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
    currencyConversions: (state, action) => {
      state.currencyConversions = action.payload;
    },
    setShowFilterModal_DraftPlan: (state, action) => {
      state.showFilterModal_DraftPlan = action.payload;
    },
    setShowFilterModal_ApprovedPlan: (state, action) => {
      state.showFilterModal_ApprovedPlan = action.payload;
    },
    setShowFilterModal_PastPlan: (state, action) => {
      state.showFilterModal_PastPlan = action.payload;
    },
    setPastPlanWrapper: (state, action) => {
      state.pastPlanWrapper = action.payload;
    },
    setApprovedPlanWrapper: (state, action) => {
      state.approvedPlanWrapper = action.payload;
    },
    setDraftPlanWrapper: (state, action) => {
      state.draftPlanWrapper = action.payload;
    },
    planFilters: (state, action) => {
      state.planFilters[action.payload.type] = action.payload.filters;
    },
  }
});

export const {
  searched,
  searchOptions,
  reports,
  reportsx,
  planWrapper,
  report,
  updateReport,
  categories,
  approval,
  organisation,
  beneficiaries,
  beneficiariesID,
  results,
  share,
  //goals,
  documentsList,
  downloadDocument,
  reviewsList,
  audits,
  undelete,
  childOrganisation,
  fetchStatus,
  assetTypes,
  isJustCommented,
  allComments,
  selectedCurrency,
  currencyConversions,
  resetSpoState,
  setShowFilterModal_DraftPlan,
  setShowFilterModal_ApprovedPlan,
  setShowFilterModal_PastPlan,
  setPastPlanWrapper,
  setApprovedPlanWrapper,
  setDraftPlanWrapper,
  planFilters,
} = spoSlice.actions;

export const selectFetchStatus = (state: RootState) => state.spo.fetchStatus;

export const selectSearched = (state: RootState) => state.spo.searched;

export const selectSearchOptions = (state: RootState) =>
  state.spo.searchOptions;

export const selectReports = (state: RootState) => state.spo.reports;

export const selectReportsx = (state: RootState) => state.spo.reportsx;

export const selectPlanWrapper = (state: RootState) => state.spo.planWrapper;

export const selectReport = (state: RootState) => state.spo.report;

export const selectCategories = (state: RootState) => state.spo.categories;

export const selectOrganisation = (state: RootState) => state.spo.organisation;

export const selectChildOrganisation = (state: RootState) =>
  state.spo.childOrganisation;

export const selectCategory = (state: RootState) => state.spo.category;

export const selectBeneficiaries = (state: RootState) =>
  state.spo.beneficiaries;

export const selectBeneficiariesID = (state: RootState) =>
  state.spo.beneficiariesID;

export const selectResults = (state: RootState) => state.spo.results;

export const selectShare = (state: RootState) => state.spo.share;

export const selectDocuments = (state: RootState) => state.spo.documentsList;

export const selectDownloadDoc = (state: RootState) =>
  state.spo.downloadDocument;

export const selectAudits = (state: RootState) => state.spo.audits;

export const selectReviewsList = (state: RootState) => state.spo.reviewsList;

export const selectUndelete = (state: RootState) => state.spo.undelete;

export const selectAssetTypes = (state: RootState) => state.spo.assetTypes;

export const selectIsJustCommented = (state: RootState) =>
  state.spo.isJustCommented;

export const selectAllComments = (state: RootState) => state.spo.allComments;

export const selectSelectedCurrency = (state: RootState) =>
  state.spo.selectedCurrency;

export const selectCurrencyConversions = (state: RootState) =>
  state.spo.currencyConversions;

export const selectShowFilterModal_DraftPlan = (state: RootState) =>
  state.spo.showFilterModal_DraftPlan;

export const selectShowFilterModal_ApprovedPlan = (state: RootState) =>
  state.spo.showFilterModal_ApprovedPlan;

export const selectPastPlanWrapper = (state: RootState) =>
  state.spo.pastPlanWrapper;

export const selectApprovedPlanWrapper = (state: RootState) =>
  state.spo.approvedPlanWrapper;

export const selectDraftPlanWrapper = (state: RootState) =>
  state.spo.draftPlanWrapper;

export const selectShowFilterModal_PastPlan = (state: RootState) =>
  state.spo.showFilterModal_PastPlan;

export const selectPlanFilters = (state: RootState, type: PLAN_SCREEN_SECTION) => state.spo.planFilters?.[type];

export const fetchOrganisation =
  (force: boolean = true) =>
    async (dispatch: Function, getState: () => RootState): Promise<boolean | void> => {
      try {
        const currentState = getState();
        const hasOrgs = !!currentState.spo.organisation;
        // If Organisation are already present, no need to fetch them again
        if (!force && hasOrgs) return;

        const response = await apiService.fairtradeV10OrgProfileList();
        // Store user org type ('SPO' or 'HL')
        // return null if non PO user
        const producerSetup = response.data.data?.find(({ key }) => key === 'ProducerSetup')?.value;
        const org = PRODUCER_SETUPS.find(({ id }) => producerSetup === id);
        const data = { ...response.data, _type: org?.orgType };
        dispatch(organisation(data));
      } catch (error) {
        console.error(error);
      }
    };

export const fetchOrganisationById =
  (orgId: number) =>
    async (dispatch: Function): Promise<GenericHttpResult<ApiOrganization>> => {
      try {
        const response = await apiService.organizationV20ItemDetail(orgId);
        dispatch(organisation(response.data));
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

export const fetchOrgTypeById =
  (orgId: number) =>
    async (): Promise<GenericHttpResult<ApiItemData>> => {
      const enumName = 'OrgType';
      const response = await apiService.organizationV10ItemEnumValueDetail(orgId, enumName);
      return response;
    };

export const fetchReports =
  () =>
    async (
      dispatch: Function,
      getState: Function,
      { callEndpoint }: ThunkArgs
    ): Promise<any> => {
      const data = selectReports(getState());
      if (data?.length) return;
      dispatch(fetchStatus('fetching'));
      const response: IResponse = await dispatch(
        callEndpoint({ api: 'api/Plan/V1.1/List', method: 'GET' }) // TODO: Deprecated, May need to be removed
      );
      dispatch(fetchStatus('idle'));
      if (response?.status === 'success' && !response.errors) {
        const data = response.data.map((r) => {
          const floid = r?.orgData?.find((d) => d?.key === 'FLOID')?.value;
          return { ...r, floid };
        });
        dispatch(reports(data));
        return data;
      }
      return [];
    };

export const fetchReport =
  (id: number) =>
    async (dispatch: Function): Promise<GenericHttpResult<ApiFullPlan>> => {
      try {
        const response = await apiService.planV10ItemDetail(id);
        dispatch(updateReport(response.data));
        return response;
      }
      catch (error) {
        console.error(error);
        throw error;
      }
    };

export const getPlanStatus =
  (status: number) =>
    async (dispatch: Function): Promise<(ApiFullPlan | { floid: string | null | undefined })[]> => {
      try {
        dispatch(fetchStatus('fetching'));
        const response = await apiService.planV10ListDetail(status);
        const data = response.data.map((r) => {
          const floid = r?.orgData?.find((d) => d?.key === 'FLOID')?.value;
          return { ...r, floid };
        });
        dispatch(reportsx(data));
        return data;
      } catch (error) {
        return [];
      } finally {
        dispatch(fetchStatus('idle'));
      }
    };

export const fetchPlanWrapper =
  (status: number) =>
    async (
      dispatch: Function,
      getState: Function,
      { callEndpoint }: ThunkArgs
    ): Promise<any> => {
      dispatch(fetchStatus('fetching'));
      const response: IResponse = await dispatch(
        // callEndpoint({ api: 'api/Plan/V1.0/List', method: 'GET' })
        callEndpoint({
          api: `api/Fairtrade/PlanWrapper/V1.0/List/${status}`, // TODO: Deprecated, May need to be removed
          method: 'GET'
        })
      );
      dispatch(fetchStatus('idle'));
      if (response?.status === 'success' && !response.errors) {
        const data = response.data.map((r) => {
          const floid = r?.orgData?.find((d) => d?.key === 'FLOID')?.value;
          return { ...r, floid };
        });
        dispatch(planWrapper(data));
        return data;
      }
      return [];
    };

export const fetchMessages =
  (itemType: number, itemId: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiMessageItem[]> | ApiMessageItem[] | boolean> => {
      const response = await apiService.messageV10ItemsDetail(itemType, itemId);
      return (response.status === 200 && response.data);
    };

export const patchMessageProperties =
  (messageProperties: IMessageProperties[]) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.messageV10ItemsPropertyPartialUpdate(messageProperties);
      return response;
    };

export const createMessage =
  (itemType: number, itemId: number, message: IMessageItemSummary) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      try {
        const response = await apiService.messageV10ItemCreate2(itemType, itemId, message);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

export const getPlanManagement =
  () =>
    async (dispatch: Function): Promise<GenericHttpResult<ApiOrganizationSimpleOut[]>> => {
      try {
        const response = await apiService.fairtradeChildOrgsV10PlanManagementList();
        dispatch(childOrganisation(response.data || []));
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

export const getOrgManagementList =
  () =>
    async (dispatch: Function): Promise<GenericHttpResult<ApiOrganizationSimpleOut[]> | void> => {
      try {
        const response = await apiService.fairtradeChildOrgsV10OrgManagementList();
        const data = (response?.data || []).map((item) => {
          const producerSetup = item?.data?.find(({ key }) => key === 'ProducerSetup')?.value;
          const orgType = PRODUCER_SETUPS.find(({ id }) => id === producerSetup)?.orgType;
          return { ...item, _type: orgType };
        });
        dispatch(childOrganisation(data));
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

export const getOrgManagementListById =
  (orgId: number) =>
    async (): Promise<GenericHttpResult<ApiOrganizationSimpleOut[]>> => {
      const response = await apiService.fairtradeChildOrgsV11UserManagementDetail(orgId);
      return response;
    };

export const searchReports =
  (text: string) =>
    async (
      dispatch: Function
    ): Promise<GenericHttpResult<ApiFTPlanSearchText[]>> => {
      dispatch(fetchStatus('fetching'));
      const response: any = await apiService.planV10SearchTextDetail(text);
      dispatch(fetchStatus('idle'));
      if (response?.status === 200) {
        const data = response.data.map((r) => {
          const floid =
            r.floid || r?.orgData?.find((d) => d?.key === 'FLOID')?.value;
          return { ...r, floid };
        });
        dispatch(searched(data));
      }
      return response;
    };

export const deleteReport =
  (id: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.planV10ItemDelete(id);
      return response;
    };

export const deleteApprovedPlan =
  (planId: number, reason: string) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.fairtradePlanWrapperV10ApprovedPlanDelete(planId, { reason });
      return response;
    };

export const saveReport =
  () =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<number> | GenericHttpResult<void> | { [key: string]: any }> => {
      const data: Report | null = selectReport(getState());
      if (!data) return { status: 'error', errors: ['no data'] };
      const report: Partial<Report> = { ...data };
      const requestMethod = data?.id < 0 ? 'POST' : 'PATCH';
      if (requestMethod === 'PATCH') {
        //FIXME: SHOULDN't delete wrapperID
        //
        // According to BackEnd, PATCH request should be made
        // without Wrapper ID.
        delete report.wrapperId;
      }
      if (report.startDate)
        report.startDate = new Date(report.startDate)?.toISOString() || null;
      if (report.startDate === '') report.startDate = null;
      if (report.endDate)
        report.endDate = new Date(report.endDate)?.toISOString() || null;
      if (report.endDate === '') report.endDate = null;
      if (report.proxyOrgId && requestMethod === 'POST')
        report.orgId = report.proxyOrgId;

      const errors = ableToSubmit(report as Report);
      const incompleteFields = errors?.length;
      report.incompleteFields = incompleteFields;
      
      window.localStorage.removeItem('report');
      const requestAction = (data.id && data.id < 0) ? apiService.planV11ItemCreate : apiService.planV10ItemPartialUpdate;
      const response = await requestAction(report as ApiPlan);
      return response;
    };

export const createNewInvestment = (data: Partial<Report>) => async (dispatch: Function) => {
  const reponse = await apiService.planV11ItemCreate(data as ApiPlan);
  return reponse;
};

export const editPlan =
  () =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void> | { [key: string]: any }> => {
      const data: Report | null = selectReport(getState());
      if (!data) return { status: 'error', errors: ['no data'] };
      const report: Partial<Report> = { ...data };

      const errors = ableToSubmit(report as Report);
      const incompleteFields = errors?.length;
      report.incompleteFields = incompleteFields;
      
      const response = await apiService.planV10ItemPartialUpdate(report as ApiPlan);
      return response;
    };

export const sharePlan =
  (planIds: number[], shareMessage: string) =>
    async (
      dispatch: Function,
      getState: Function,
      { callEndpoint }: ThunkArgs
    ): Promise<IResponse> => {
      const response: IResponse = await dispatch(
        callEndpoint({
          api: 'api/Plan/V1.0/Share', // TODO: Deprecated, May need to be removed
          method: 'PATCH',
          body: { planIds, shareMessage }
        })
      );
      if (response?.status === 'success' && !response.errors) {
        dispatch(share(response.data));
      }
      return response;
    };

export const storeReport =
  (data: Report) =>
    async (dispatch: Function): Promise<void> => {
      dispatch(report(data));
      dispatch(updateReport(data));
      setTimeout(() => {
        window.localStorage.setItem('report', JSON.stringify(data));
      });
    };

export const initReport =
  () =>
    async (dispatch: Function): Promise<void> => {
      const json = window.localStorage.getItem('report');
      json && dispatch(report(JSON.parse(json)));
    };

export const approveReport =
  (id: number, approvedBy: number, approvedOn: string, documents: any[]) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<number> | { [key: string]: any }> => {
      if (!id) return { status: 'error', errors: ['no id'] };
      const data = {
        planId: id,
        approvedBy,
        approvedOn,
        documents
      };
      const response = await apiService.planV11ApproveCreate(data);
      return response;
    };

export const approvePlanWrapper =
  (id: number, approvedBy: number, approvedOn: string, documents: any[]) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<any> => {
      if (!id) throw { data: { description: 'Missing Wrapper ID' } };
      const data = {
        wrapperId: id,
        approvedBy,
        approvedOn,
        documents
      };
      const response = await apiService.fairtradePlanWrapperV10ApproveCreate(data);
      return response;
    };

export const approvePlanWrapperResult =
  (id: number, approvedBy: number, approvedOn: string, documents: any[]) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void> | { [key: string]: any }> => {
      if (!id) return { status: 'error', errors: ['no id'] };
      const data = {
        wrapperId: id,
        approvedBy,
        approvedOn,
        documents
      };
      const response = apiService.fairtradePlanWrapperV10ApproveResultCreate(data);
      return response;
    };

export const undeletePlan2 =
  (planId: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void> | { [key: string]: any }> => {
      if (!planId) return { status: 'error', errors: ['no id'] };
      const response = await apiService.fairtradePlanWrapperPlanUndeleteCreate(planId);
      return response;
    };

//FIXME: Why does this action require the entire report as an additional argument?
export const saveResults =
  (data: ApiPlanResult, report: Report) =>
    async (
      dispatch: Function,
      getState: Function,
    ) => {
      let response: GenericHttpResult<void>;
      try {
        if (!data) {
          throw new Error('No data has been passed');
        }

        if (data.actualStartDate)
          data.actualStartDate = new Date(data.actualStartDate).toISOString();
        if (data.actualEndDate)
          data.actualEndDate = new Date(data.actualEndDate).toISOString();
        data.planId = report.id;

        response = !data?.id
          ? await apiService.fairtradePlanResultV10ResultCreate(data)
          : await apiService.fairtradePlanResultV10ResultPartialUpdate(data as ApiFullPlanResult);

        if (response?.status === 204) {
          //FIXME: The api returns no content. What are we storing?
          dispatch(results(response.data));
        }
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

export const fetchResults =
  (id: number) =>
    async (
      dispatch: AppDispatch,
      getState: Function
    ) => {
      try {
        if (!id || id < 0) {
          throw new Error('Invalid Plan Id');
        }
        const response = (
          await apiService.fairtradePlanResultV10ResultDetail(id)
        ) as unknown as GenericHttpResult<Results>;
        if (response.status === 200) {
          dispatch(results(response.data));
        } else {
          dispatch(results({ id: -1 }));
        }
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

export const batchFetchResults =
  (ids: number[]) =>
    async (
      dispatch: AppDispatch,
      getState: Function
    ): Promise<IResponse> => {
      if (!ids || !ids.length) return { status: 'error' } as IResponse;
      const resultsByID: { [key: number]: any } = {};
      let errors = false;

      for (const id of ids) {
        if (id === undefined || id < 0) continue;
        const response: any = await fetchResults(id);
        if (response?.status === 200) {
          resultsByID[id] = response.data;
        } else {
          errors = true;
        }
      }
      return {
        status: errors ? 'error' : 'success',
        data: resultsByID
      };
    };

export const approveResults =
  (id: number, approvedBy: number, approvedOn: string) =>
    async (
      dispatch: Function,
      getState: Function
    ) => {
      if (!id) return { status: 'error', errors: ['no id'] };
      const data = {
        planId: id,
        approvedBy,
        approvedOn
      };
      const response = await apiService.fairtradePlanResultV10ApproveCreate(data);
      return response;
    };

export const fetchCategories =
  // FairTrade => get both SPO and HL cats
  (force: boolean = true) =>
    async (
      dispatch: Function,
      getState: () => RootState // Get the current state using getState
    ): Promise<boolean> => {
      // Check if categories are already present in the store
      const currentState = getState();
      if (!force && !!currentState.spo.categories) {
        // Categories are already present, no need to fetch them again
        return true;
      }

      const response = await apiService.fairtradeV11CategoriesList();

      // Dispatch the categories action with the fetched data
      if (response?.status === 200) {
        dispatch(categories(response.data));
        return true;
      }

      return false;
    };

export const fetchBeneficiaries =
  (force: boolean = true) =>
    async (
      dispatch: Function,
      getState: () => RootState // Get the current state using getState
    ): Promise<boolean> => {
      // Check if beneficiaries are already present in the store
      const currentState = getState();
      if (!force && !!currentState.spo.beneficiaries) {
        // beneficiaries are already present, no need to fetch them again
        return true;
      }

      const response = await apiService.planV11BeneficiariesList();

      // Dispatch the beneficiaries action with the fetched data
      if (response?.status === 200) {
        dispatch(beneficiaries(response.data));
        return true;
      }

      return false;
    };

export const fetchBeneficiariesID =
  (orgId: number, force: boolean = true) =>
    async (
      dispatch: Function,
      getState: () => RootState // Get the current state using getState
    ): Promise<boolean> => {
      // Check if beneficiaries are already present in the store
      const currentState = getState();
      if (!force && !!currentState.spo.beneficiariesID) {
        // beneficiaries are already present, no need to fetch them again
        return true;
      }

      const response = await apiService.planV11BeneficiariesDetail(orgId);

      // Dispatch the beneficiariesID action with the fetched data
      if (response?.status === 200) {
        dispatch(beneficiariesID(response.data));
        return true;
      }

      return false;
    };

export const init =
  () =>
    async (dispatch: Function, getState: Function): Promise<void> => {
      const creds = selectCredentials(getState());
      if (!!creds?.securityToken) {
        await dispatch(fetchOrganisation());
        await dispatch(getPlanManagement());
        await dispatch(getOrgManagementList());
        await dispatch(fetchAssetTypes());
      }
      await dispatch(initReport());
    };

export const fetchAudits =
  (planId: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiPlanAudit[]>> => {
      const response = await apiService.planV10AuditsDetail(planId);
      if (response?.status === 200) {
        dispatch(audits(response.data));
      }
      return response;
    };

export const saveItemData =
  (id: number, itemType: number, itemId: number, key: string, value: string) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      if (id < 0) {
        const response = await apiService.itemDataV10ItemCreate({ itemType, itemId: ((itemId < 0) ? undefined : itemId), key, value: value.toString() });
        return response;
      } else {
        const response = await apiService.itemDataV10ItemPartialUpdate({ id, itemType, itemId: ((itemId < 0) ? undefined : itemId), key, value: value.toString() });
        return response;
      }
    };

export const saveOrgDescription =
  (description: string) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void> | { [key: string]: any }> => {
      if (!description) return { status: 'error', errors: ['no data'] };
      const org = selectOrganisation(getState());
      const newOrg = { ...org };
      newOrg.publicDescription = description;
      const response = await apiService.planV10OrgProfilePartialUpdate(newOrg);
      if (response?.status === 200) {
        dispatch(organisation(newOrg));
      }
      return response;
    };

export const uploadFile =
  (
    referenceId: number | number[] | null,
    itemIds: number[],
    itemType: FILE_TYPES,
    file: File,
    name?: string,
    description?: string,
    url?: string
  ) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<any> => {
      if (!itemIds) return { status: 'error', errors: ['no data'] };
      const data: any = new FormData();
      const fileBlob = new Blob([file?.slice()], { type: file?.type });
      data?.append('document', fileBlob, file ? file?.name : name);
      data?.append(
        'documentDetails',
        JSON.stringify({ referenceId, itemId: itemIds?.[0], itemType, name, description, url })
      );
      const response = await apiService.documentV12UploadCreate(data);
      return response;
    };

export const uploadWrapperFile =
  (
    referenceId: number | number[] | null,
    itemId: number,
    itemType: FILE_TYPES,
    file: File,
    name?: string,
    description?: string,
    url?: string
  ) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<any> => {
      if (!itemId) return { status: 'error', errors: ['no data'] };
      const data: any = new FormData();
      const fileBlob = new Blob([file?.slice()], { type: file?.type });
      data?.append('document', fileBlob, file ? file?.name : name);
      data?.append(
        'documentDetails',
        JSON.stringify({ referenceId, itemId, itemType, name, description, url })
      );
      const response = await apiService.documentV12UploadCreate(data);
      return response;
    };

export const fetchFiles =
  (id: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiDocumentPreview[]>> => {
      if (id < 0 || id === undefined || id === null) throw { data: { description: 'Missing Id' } };
      const response = await apiService.planV10ItemDocumentsDetail(id);
      if (response?.status === 200 && response.data) {
        dispatch(documentsList(response.data));
      }
      return response;
    };

export const batchFetchFiles =
  (ids: number[]) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<IResponse> => {
      if (!ids || !ids.length) throw { data: { description: 'Missing Ids' } };
      const resultsByID: { [key: number]: any } = {};
      let errors = false;

      for (const id of ids) {
        if (id === undefined || id < 0) throw { data: { description: 'Missing Id' } };
        const response = await apiService.planV10ItemDocumentsDetail(id);
        if (response?.status === 200 && response.data) {
          resultsByID[id] = response.data;
        } else {
          errors = true;
        }
      }

      // if (!errors) {
      //   dispatch(groupResultsByID(resultsByID));
      // }

      return {
        status: errors ? 'error' : 'success',
        data: resultsByID
      };
    };

export const fetchWrapperFiles =
  (wrapperId: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiDocument[]> | { [key: string]: any }> => {
      if (wrapperId < 0) return { status: 'error' };
      const response = await apiService.fairtradePlanWrapperV10DocumentsListDetail(wrapperId);
      if (response?.status === 200) {
        dispatch(documentsList(response.data));
      }
      return response;
    };

export const fetchRelease =
  (id: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiDocument[]>> => {
      const response = await apiService.documentV10ListDetail(FILE_TYPES.RELEASE as number, id);
      return response;
    };

export const downloadDoc =
  (key: string) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<any>> => {
      const response: any = await apiService.documentV10DownloadDetail(key);

      if (response?.status === 200 && response.data) {
        dispatch(downloadDocument(URL.createObjectURL(response?.data as Blob)));
      }
      return response;
    };

export const deleteFile =
  (key: string) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.documentV10DeleteDelete(key);
      return response;
    };

export const undeletePlan =
  (planId: number, yearPeriod?: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const period = yearPeriod !== -1 && yearPeriod ? yearPeriod : -1;
      const response = await apiService.planV11UndeletePartialUpdate(planId, period);
      return response;
    };

export const unapproveWrapper =
  (wrapperId: number, keep?: boolean) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.fairtradePlanWrapperItemV10UnapprovePartialUpdate(wrapperId, Boolean(keep));
      return response;
    };

export const fetchAssetTypes =
  () =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiAssetType[]>> => {
      const response = await apiService.assetTypeV20ListList();
      if (response.status === 200) {
        dispatch(assetTypes(response.data));
      }
      return response;
    };

export const updateReferencePeriodForPlan =
  (instance: any) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.planV10ItemPartialUpdate(instance);
      return response;
    };
export const fetchWrapperSummary =
  (wrapperId: PlanWrapper['id']) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiPlanWrapper> | ApiPlanWrapper | boolean> => {
      const response = await apiService.fairtradePlanWrapperV10ItemSummaryDetail(wrapperId);
      return (response?.status === 200 && response.data);
    };

export const fetchWrapperDetails =
  (wrapperId: PlanWrapper['id']) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiPlanWrapper>> => {
      const response = await apiService.fairtradePlanWrapperV10ItemDetail(wrapperId);
      return response;
    };

export const updateWrapperStatus =
  (wrapperId: PlanWrapper['id'], wrapperStatus: PlanWrapper['status']) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.fairtradePlanWrapperItemV10StatusCreate(wrapperId, wrapperStatus);
      return response;
    };

export const fetchWrapperSummary2 =
  (floid: string | number) =>
    async (): Promise<GenericHttpResult<ApiPlanWrapper[]>> => {
      const response = await apiService.fairtradePlanWrapperV10ListSummaryDetail(floid.toString());
      return response;
    };

export const updatePlanStatus =
  (planId: number, newPlanStatus: PLAN_STATUS) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = await apiService.fairtradePlanWrapperPlanStatusCreate(planId, newPlanStatus as unknown as ApiPlanStatus);
      return response;
    };

export const getCurrencyConversions =
  () =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiMWCurrencyData[]>> => {
      const response = await apiService.marketDataV10ExchangeRateAllCreate({ setName: 'Fairtrade' });
      return response;
    };

export const downloadBulkTemplate =
  (orgId: number, type: number, referenceYear?: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<Blob>> => {
      if (type === 1 && referenceYear) {
        const response = await apiService.fairtradeV10UploadDocumentsDetail(orgId, type, referenceYear);
        return response;
      } else {
        const response = await apiService.fairtradeV10UploadDocumentsDetail2(orgId, type);
        return response;
      }
    };

export const bulkToJSON =
  (document: any) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<Record<string, string>[]>> => {
      const pageId = 1;
      const formData: any = new FormData();
      formData.append('document', document);
      const response = await apiService.documentV10ExcelExtractJsonCreate(pageId, formData);
      return response;
    };

export const bulkSubmit =
  (document: any, orgId: number, startYear: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.planV10BulkItemsCreate(orgId, startYear, JSON.parse(document));
      return response;
    };

export const bulkSubmitResult =
  (document: any, orgId: number) =>
    async (
      dispatch: Function
    )  => {
      const response = await apiService.fairtradePlanResultV10BulkItemsCreate(orgId, JSON.parse(document));
      return response;
    };

export const getWrapperByOrgAndYear =
  (orgId: number, year?: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiPlanWrapper>> => {
      const response = await apiService.fairtradePlanWrapperV10ItemDetail2((orgId as number), (year as number));
      return response;
    };

export const ChangeOrgCurrency =
  (wrapperId: number, currencyId: number, convertValue: boolean) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<void>> => {
      const response = await apiService.fairtradePlanWrapperV10ItemCurrencyPartialUpdate(wrapperId, currencyId, convertValue);
      return response;
    };

export const fetchDeletedPlans =
  (wrapperId: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiDeletedInvestment> | { [key: string]: any }> => {
      if (wrapperId < 0) return { status: 'error' };
      const response = await apiService.fairtradePlanWrapperV10ItemDeletedPlansDetail(wrapperId);
      if (response?.status === 200) {
        dispatch(documentsList(response.data));
      }
      return response;
    };

export const fetchDeletedInvestment =
  (id: number) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiFullPlan> | { [key: string]: any }> => {
      if (id < 0) return { status: 'error' };
      const response = await apiService.planV10ItemDetail(id);
      if (response?.status === 200) {
        dispatch(documentsList(response.data));
      }
      return response;
    };

export const fetchUsedFloids =
  () =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<string[]>> => {
      const response = await apiService.fairtradeV10UsedFloidsList();
      return response;
    };

export const getPlanList =
  (status: number, pastPlanQuery: ApiPastPlanQuery, params?: FairTradeRequestParams) =>
    async (
      dispatch: Function,
      getState: Function
    ): Promise<GenericHttpResult<ApiPlanWrapper[]> | { [key: string]: any }> => {
      if (!pastPlanQuery) return { status: 'error', errors: ['no params'] };
      const response = await apiService.fairtradePlanWrapperV11ListCreate(status, pastPlanQuery, params);
      return response;
    };

export default spoSlice.reducer;
