import { createSlice } from '@reduxjs/toolkit';
import {
  ApiUnitType,
  ApiCODImpact,
  ApiCODImpactStatus,
  ApiCODImpactSummaryItem,
  ApiCODImpactSummaryRequest,
  ApiFTConsentItem,
  ApiItemGroup,
  ApiOrganizationSimpleOut,
  ApiPOGeoLink,
  GenericHttpResult,
} from '@agunity/api-v4';

import { apiService } from 'api/apiService';
import { selectProfile } from 'features/login/redux';
import { selectOrganisation } from 'features/SPOReport/redux';
import { selectFeatureFlags } from 'features/Administration/redux';
import { ConsentState, FEATURE_FLAGS, FILE_TYPES, PERMISSIONS } from 'appConstants';
import type { RootState } from 'app/store';

export const hot = '../features/OrganizationDetails/redux.ts';

type UploadFile = {
  file: File | null;
  itemType: number;
  itemId: number;
  name?: string;
  description?: string;
  url?: string;
};

export type UpdateOrgConsentState = {
  itemType: number;
  itemId: number;
  consentId: number;
  state: number;
};

export type CODSubset = {
  isActive?: boolean;
  floIds?: number[] | null;
  status?: ApiCODImpactStatus;
  years?: number[] | null;
};

const initialState = {
  consentItems: [],
  orgFloIds: [],
  codImpacts: [],
  codSubset: { isActive: false, floid: [], status: undefined, year: [] },
  geoLinksList: null,
  codForm: [],
  productTree: {},
  countriesIDList: [],
  unitsList: { volume: [], area: [] },
  volumeUnitsListViaProductCode: [],
  areaUnitsListViaProductCode: [],
  certificateList: {},
  optionsList: { colorRange: [], harvestTypes: [], volumesProducedActualAmounts: [], volumesProducedEstimated: [], processingTypeWine: [], processingTypeTea: [], processingTypeSugar: [], months: [] }
};

export const counterSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    resetOrganization: () => initialState,
    setConsentItems: (state, action) => {
      state.consentItems = action.payload;
    },
    setOrgFloIds: (state, action) => {
      state.orgFloIds = action.payload;
    },
    setCODImpacts: (state, action) => {
      state.codImpacts = action.payload;
    },
    setCODSubset: (state, action) => {
      state.codSubset = action.payload;
    },
    setCODForm: (state, action) => {
      state.codForm = action.payload;
    },
    geoLinksList: (state, action) => {
      state.geoLinksList = action.payload;
    },
    productTree: (state, action) => {
      state.productTree = action.payload;
    },
    countriesIDList: (state, action) => {
      state.countriesIDList = action.payload;
    },
    unitsList: (state, action) => {
      state.unitsList = action.payload;
    },
    volumeUnitsListViaProductCode: (state, action) => {
      state.volumeUnitsListViaProductCode = action.payload;
    },
    areaUnitsListViaProductCode: (state, action) => {
      state.areaUnitsListViaProductCode = action.payload;
    },
    optionsList: (state, action) => {
      state.optionsList = action.payload;
    },
    certificateList: (state, action) => {
      state.certificateList = action.payload;
    }
  },
});

export const {
  resetOrganization,
  setConsentItems,
  setOrgFloIds,
  setCODImpacts,
  setCODSubset,
  geoLinksList,
  setCODForm,
  productTree,
  countriesIDList,
  unitsList,
  volumeUnitsListViaProductCode,
  areaUnitsListViaProductCode,
  optionsList,
  certificateList
} = counterSlice.actions;

export const selectConsentItems = (state: RootState): ApiFTConsentItem[] => {
  return state.organization.consentItems;
};

export const selectOrgFloIds = (
  state: RootState
): ApiOrganizationSimpleOut[] => {
  return state.organization.orgFloIds;
};

export const selectCODImpacts = (
  state: RootState
): ApiCODImpactSummaryItem[] => {
  return state.organization.codImpacts;
};

export const selectCODForm = (state: RootState): ApiCODImpact[] => {
  return state.organization.codForm;
};

export const selectCODSubset = (state: RootState): CODSubset => {
  return state.organization.codSubset;
};

export const getGeoLinksList = (state: RootState): any[] | null =>
  state.organization.geoLinksList;

export const getProductTreeList = (state: RootState): { [key: string]: any } =>
  state.organization.productTree;

export const getCountriesIDList = (state: RootState): any[] => (state.organization.countriesIDList || []);

export const getUnitsList = (state: RootState): { volume: any[], area: any[] } => (state.organization.unitsList || { volume: [], area: [] });

export const getVolumeUnitsListViaProductCode = (state: RootState): any[] => (state.organization.volumeUnitsListViaProductCode || []);

export const getAreaUnitsListViaProductCode = (state: RootState): any[] => (state.organization.areaUnitsListViaProductCode || []);

export const getOptionsList = (state: RootState): { colorRange: any[], harvestTypes: any[], volumesProducedActualAmounts: any[], volumesProducedEstimated: any[], processingTypeWine: any[], processingTypeTea: any[], processingTypeSugar: any[], months: any[] } => (state.organization.optionsList || {});

export const getCertificateList = (state: RootState): { [key: string]: any } =>
  state.organization.certificateList;

export const updateOrgConsentState = ({
  itemType,
  itemId,
  consentId,
  state,
}: UpdateOrgConsentState) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.consentV10ItemPartialUpdate2(
      itemType,
      itemId,
      consentId,
      state
    );
    return response;
  };
};

export const getOrgConsentList = (force: boolean = true) => {
  return async (
    dispatch: Function,
    getState: Function
  ): Promise<GenericHttpResult<ApiFTConsentItem[]> | void> => {
    const consentItems = selectConsentItems(getState());
    if (!force && consentItems.length) return;
    const response = await apiService.fairtradeConsentOrgV10ListList();
    if (response.status === 200) dispatch(setConsentItems(response.data));
    return response;
  };
};

export const getOrgFLOIDs = (orgId: number, force: boolean = true) => {
  return async (
    dispatch: Function,
    getState: Function
  ): Promise<GenericHttpResult<ApiOrganizationSimpleOut[]> | void> => {
    if (!orgId) return;
    const orgFloIds = selectOrgFloIds(getState());
    if (!force && orgFloIds.length) return;
    const response = await apiService.fairtradeChildOrgsV11UserManagementDetail(
      orgId
    );
    if (response.status === 200) dispatch(setOrgFloIds(response.data));
    return response;
  };
};

export const fetchFilesDetails = (id: number) => {
  return async () => {
    const response = await apiService.documentV10ListDetail(FILE_TYPES.CODImpact as number, id);
    return response;
  };
};

export const uploadFile = ({ file, ...params }: UploadFile) => {
  return async (): Promise<GenericHttpResult<number> | void> => {
    if (!file) return;
    const data: any = new FormData();
    const fileBlob = new Blob([file.slice()], { type: file.type });
    data?.append('document', fileBlob, file.name ?? params?.name);
    data?.append('documentDetails', JSON.stringify(params));
    const response = await apiService.documentV12UploadCreate(data);
    return response;
  };
};

export const deleteFile = (documentKey: string) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.documentV10DeleteDelete(documentKey);
    return response;
  };
};

export const downloadDocument = (documentKey: string) => {
  return async (): Promise<GenericHttpResult<Blob>> => {
    const response = await apiService.documentV10DownloadDetail(documentKey);
    return response;
  };
};

export const getCODImpactList = (data: ApiCODImpactSummaryRequest) => {
  return async (
    dispatch: Function
  ): Promise<GenericHttpResult<ApiCODImpactSummaryItem[]>> => {
    const response = await apiService.fairtradeCodV10ListCreate(data);
    if (response.status === 200) dispatch(setCODImpacts(response.data));
    return response;
  };
};

export const getCODImpactYearList = () => {
  return async (): Promise<GenericHttpResult<number[]>> => {
    const response = await apiService.fairtradeCodV10ListYearsList();
    return response;
  };
};

export const getCODImpactByYear = ({
  orgId,
  year,
}: {
  orgId: number;
  year: number;
}) => {
  return async (
    dispatch: Function
  ): Promise<GenericHttpResult<ApiCODImpact[]>> => {
    const response = await apiService.fairtradeCodV10ItemDetail(orgId, year);
    await dispatch(setCODForm(response.data || []));
    return response;
  };
};

export const postCODImpact = (orgId: number, data: ApiCODImpact) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.fairtradeCodV10ItemCreate(orgId, data);
    return response;
  };
};

export const initOrgConsent = (history) => {
  return async (dispatch: Function, getState: Function) => {
    const permissions = selectProfile(getState())?.permissions || [];
    const featureFlags = selectFeatureFlags(getState());
    const isPOProxy = permissions.includes(PERMISSIONS.PO_PROXY_READ);

    if (isPOProxy) {
      // Fetch the org FloId list and consent item list.
      const orgProfile = selectOrganisation(getState());
      await dispatch(getOrgFLOIDs(orgProfile?.id));

      const response = await dispatch(getOrgConsentList());

      // If at least one item has not yet been agreed/disagreed, redirect the user to the Org consent page.
      const hasNotYetGivenConsent = (response.data || []).some(
        ({ state }) => state === ConsentState.Blank
      );
      const hasOrgConsent = (featureFlags || []).includes(FEATURE_FLAGS[9]);
      if (hasNotYetGivenConsent && hasOrgConsent) {
        history.replace('/organizationDetails/organizationConsents');
      }
    }
  };
};

export const fetchGeoLinks = () => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<ApiPOGeoLink[]>> => {
  const response = await apiService.fairtradeV10GeoLinksList();
  if (response.status === 200) {
    dispatch(geoLinksList(response.data));
  } else {
    dispatch(geoLinksList([]));
  }
  return response;
});

export const updateGeoLink = ({ method, data }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const api = { POST: apiService.itemDataV10ItemCreate, PATCH: apiService.itemDataV11ItemPartialUpdate };
  const { id, geoLink } = data;
  const body = { itemType: 1, /* Item Type 1 = Organization */ key: 'GeoLink', /* Item Type */ itemId: id, /* Organization ID */ value: geoLink /* GeoLink */ };
  const response = await api[method](body);
  if (response.status === 200) { dispatch(fetchGeoLinks()); }
  return response;
});

export const uploadGeoLinks = ({ data }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const response = await apiService.fairtradeV10GeoLinksCreate(data);
  if (response.status === 200) { dispatch(fetchGeoLinks()); }
  return response;
});

export const downloadGeoLinksDocument = ({ key }) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<Blob>> => {
  const response = await apiService.documentV10DownloadDetail(key);
  return response;
});

export const fetchProductTree = (documentKey: string) => (async (dispatch: Function) => {
  const response = await apiService.documentV10DownloadDetail(documentKey);
  if (response.status === 200) {
    const productTreeData = JSON.parse(await response.data.text())?.ProductTree;
    const productTreeList = productTreeData.reduce((currentData, currentValue) => {
      const key = String(currentValue?.product_category).toLowerCase().replace(/,|&\s/g, '').replace(/\s|-/g, '_');
      const existingEntry = currentData?.[key] || { product_category: currentValue.product_category, products: [] };
      // const isOtherSpecify = /^(Other \[Specify\]|Otro \[especificar\]|Autre \[préciser\]|Outro \[Especifique\])$/ig.test(currentValue.product_type);
      const hasDuplicate = existingEntry.products.some((item) => (item.product_type === currentValue.product_type));
      if (hasDuplicate) { return currentData; }
      existingEntry.products.push(currentValue);
      return { ...currentData, [key]: existingEntry };
    }, {});
    dispatch(productTree(productTreeList));
    return productTreeList;
  }
  return response;
});

export const fetchSalesCountriesID = (orgId?: string) => (async (dispatch: Function, getState: Function): Promise<GenericHttpResult<ApiItemGroup[]>> => {
  const organisation = getState()?.spo?.organisation;
  const response: any = await apiService.fairtradeCodExportV10SoldCountriesDetail(Number(orgId || organisation.id));
  if (response.status === 200) { dispatch(countriesIDList(response.data)); }
  return response;
});

export const fetchUnitsList = () => (async (dispatch: Function, getState: Function) => {
  const response = await apiService.systemV10UnitsList();
  if (response.status === 200) {
    const units = response.data.reduce((currentData: any, currentUnit) => {
      const { volume, area } = currentData;
      const { unitTypeId } = currentUnit;
      if (unitTypeId === ApiUnitType.Mass || unitTypeId === ApiUnitType.Unit) {
        volume.push(currentUnit);
      } else if (unitTypeId === ApiUnitType.Distance) {
        area.push(currentUnit);
      }
      return currentData;
    }, { volume: [], area: [] });
    dispatch(unitsList(units));
  }
  return response;
});

export const fetchVolumeUnitsListViaProductCode = (code: string) => (async (dispatch: Function, getState: Function) => {
  const response = await apiService.fairtradeCodExportV10UnitListVolumnDetail(code);
  if (response.status === 200) { dispatch(volumeUnitsListViaProductCode(response.data)); }
  return response;
});

export const fetchAreanitsListViaProductCode = (code: string) => (async (dispatch: Function, getState: Function) => {
  const response = await apiService.fairtradeCodExportV10UnitListAreaDetail(code);
  if (response.status === 200) { dispatch(areaUnitsListViaProductCode(response.data)); }
  return response;
});

export const fetchCertificateList = () => (async (dispatch: Function, getState: Function) => {
  const response = await apiService.certificateV10TypesList();
  if (response.status === 200) { dispatch(certificateList(response?.data)); }
  return response;
});

export const fetchOptionsList = () => (async (dispatch: Function, getState: Function) => {
  const response = await apiService.enumV10ListList();
  if (response.status === 200) {
    const options = {
      'ColorRange': 'colorRange',
      'harvestTypeOptions': 'harvestTypes',
      'volumesProducedActualAmounts':
      'volumesProducedActualAmounts',
      'volumesProducedEstimated': 'volumesProducedEstimated',
      'ProcessingTypeWine': 'processingTypeWine',
      'ProcessingTypeTea': 'processingTypeTea',
      'ProcessingTypeSugar': 'processingTypeSugar',
      'month': 'months'
    };
    const list = response.data.reduce((currentData, { name, values }) => (name && options[name] ? { ...currentData, [options[name]]: values } : currentData), {});
    dispatch(optionsList(list));
  }
  return response;
});

export default counterSlice.reducer;
